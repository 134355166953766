import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: {
    id: 1,
    first_name: 'John',
    last_name: 'Doe',
    is_superuser: true,
    name: 'John Doe',
    email: 'johndoe@test.com',
  },
  messagesCount: 0,
  transactionsCount: 0,
  companyName: '',
  logo: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoggedIn: (state, action) => {
      const {
        user,
        access_token: token,
        unread_message_count: messagesCount,
        transactions_count: transactionsCount,
        company_name: companyName,
        company_logo: logo,
      } = action.payload;

      localStorage.setItem('token', token);
      return { ...state, isAuthenticated: true, user, messagesCount, transactionsCount, companyName, logo };
    },
    getUserDetail: (state, action) => {
      const { unread_message_count: messagesCount, transactions_count: transactionsCount, company_name: companyName, logo, ...restPayload } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user: restPayload,
        messagesCount,
        transactionsCount,
        companyName,
        logo,
      };
    },
    onLoggedOut: () => {
      localStorage.removeItem('token');

      return { ...initialState };
    },
    updateMessageCount: state => ({
      ...state,
      messagesCount: 0,
    }),
  },
});

export const { onLoggedIn, getUserDetail, onLoggedOut, updateMessageCount } = authSlice.actions;
export default authSlice.reducer;
